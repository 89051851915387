<template>
  <div>
    <CForm @submit="submit">
      <CCard>
        <CCardBody>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)">
            {{ sentMessage }}
          </CAlert>

          <CInput
              :label="$t('current_password')"
              :type="passwordIsVisible ? 'text' : 'password'"
              v-model="password"
              @keydown="resetErrors('password')"
              :invalid-feedback="_.has(errors, 'password') ? errors.password : ''"
              :is-valid="_.has(errors, 'password') ? false : null"
          >
            <template #append>
              <div class="wy-password-visible-container"
                   :class="{ 'wy-has-error': _.has(errors, 'password') }"
                   @click="passwordIsVisible = !passwordIsVisible">
                <i :class="{ 'wy-icon-visibility-open': passwordIsVisible, 'wy-icon-visibility-close': !passwordIsVisible }" style="font-size: 22px;"></i>
              </div>
            </template>
          </CInput>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('new_password')"
                  :type="newPasswordIsVisible ? 'text' : 'password'"
                  v-model="newPassword"
                  @keydown="resetErrors('newPassword')"
                  :invalid-feedback="_.has(errors, 'newPassword') ? errors.newPassword : ''"
                  :is-valid="_.has(errors, 'newPassword') ? false : null"
              >
                <template #append>
                  <div class="wy-password-visible-container"
                       :class="{ 'wy-has-error': _.has(errors, 'newPassword') }"
                       @click="newPasswordIsVisible = !newPasswordIsVisible">
                    <i :class="{ 'wy-icon-visibility-open': newPasswordIsVisible, 'wy-icon-visibility-close': !newPasswordIsVisible }" style="font-size: 22px;"></i>
                  </div>
                </template>
              </CInput>
            </div>
            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('new_password_confirmation')"
                  :type="newPasswordIsVisibleConfirmation ? 'text' : 'password'"
                  v-model="newPasswordConfirmation"
                  @keydown="resetErrors('newPasswordConfirmation')"
                  :invalid-feedback="_.has(errors, 'newPasswordConfirmation') ? errors.newPasswordConfirmation : ''"
                  :is-valid="_.has(errors, 'newPasswordConfirmation') ? false : null"
              >
                <template #append>
                  <div class="wy-password-visible-container"
                       :class="{ 'wy-has-error': _.has(errors, 'newPasswordConfirmation') }"
                       @click="newPasswordIsVisibleConfirmation = !newPasswordIsVisibleConfirmation">
                    <i :class="{ 'wy-icon-visibility-open': newPasswordIsVisibleConfirmation, 'wy-icon-visibility-close': !newPasswordIsVisibleConfirmation }" style="font-size: 22px;"></i>
                  </div>
                </template>
              </CInput>
            </div>
          </div>

        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
              <CButton type="reset" size="sm" color="secondary" class="px-4">
                <CIcon name="cil-ban"/>
                {{ $t('reset') }}
              </CButton>
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {changePassword} from "../../api/user";

export default {
  name: 'ChangePassword',
  data() {
    return {
      passwordIsVisible: false,
      newPasswordIsVisible: false,
      newPasswordIsVisibleConfirmation: false,
      errors: {},
      error: '',
      sentMessage: '',
      //
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      //
    };
  },
  methods: {
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      if (this.password === '') {
        this.errors.password = this.$t('field_is_required_IN_ARABIC_FEMALE', { field: this.$t('password') });
        hasErrors = true
      }

      if (this.newPassword === '') {
        this.errors.newPassword = this.$t('field_is_required_IN_ARABIC_FEMALE', { field: this.$t('new_password') });
        hasErrors = true
      }

      if (this.newPasswordConfirmation === '') {
        this.errors.newPasswordConfirmation = this.$t('field_is_required_IN_ARABIC_FEMALE', { field: this.$t('new_password_confirmation') });
        hasErrors = true
      }

      if (this.newPasswordConfirmation !== this.newPassword) {
        this.errors.newPasswordConfirmation = this.$t('the_new_password_confirmation_must_be_equal_to_the_new_password');
        hasErrors = true
      }

      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.validate(() => {
        changePassword(this, this.password, this.newPassword, (response) => {
          this.sentMessage = response.message;
          this.password = '';
          this.newPassword = '';
          this.newPasswordConfirmation = '';
        }, (error) => {
          if (this._.has(error, 'errors.password')) {
            this.errors.password = error.errors.password[0];
            this.$forceUpdate();
          }
          if (this._.has(error, 'errors.new_password')) {
            this.errors.newPassword = error.errors.new_password[0];
            this.$forceUpdate();
          }
        })
      });
    },
  },
}
</script>
